export const SET_CURVE = 'SET_CURVE'
export const SET_GENERATION = 'SET_GENERATION'
export const SET_GRADIENT_NAME = 'SET_GRADIENT_NAME'
export const SET_LINE_WIDTH_STYLE = 'SET_LINE_WIDTH_STYLE'
export const SET_COMPOSITE_OPERATION = 'SET_COMPOSITE_OPERATION'
export const SET_LINE_CAPS = 'SET_LINE_CAPS'
export const SET_CLEAR_BEFORE_DRAW = 'SET_CLEAR_BEFORE_DRAW'
export const SET_CLEAR_REMAINING_ANIMATIONS = 'SET_CLEAR_REMAINING_ANIMATIONS'
export const SET_DRAW_FULL = 'SET_DRAW_FULL'
export const SET_DARK_MODE = 'SET_DARK_MODE'
